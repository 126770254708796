.blogdefinitionContent{
    overflow: auto !important;
    height: 100vh !important;
}

.title_header{
    margin-top: 15%;
    color: black;
    text-align: left !important;
    font-family: Alegreya Sans SC;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    margin-left: 40px !important;
    color: #000008;
    letter-spacing: 1px;
}

.blogcontent_img{
    text-align: left !important;
    margin-left:40px !important;
}

.tags_hightlight{
    color: black;
}

.title_name{
    letter-spacing: 0.2px;
    color: #6D6D6D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: left !important;
    margin-left:40px !important;
    padding-top: 10px !important;
    display: flex;
}
.title_name p{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: #003177;
}

.blogdefinition_headertxt{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 1.2px;
    color: #010005;
    text-align: left !important;
    margin-left: 40px !important;
    max-width: 1200px;
}

.blogimgdef{
    width: 70%;
}

.blogdef_para1 ,.blogdef_para2 ,.blogdef_para3,.blogdef_para4{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #3D3D3D;
    margin-left:40px !important;
    margin-top: 30px;
    max-width: 1200px;
    text-align: left !important;

}


.blogdef_para4{
    margin-bottom: 100px;
}

.title_header1{
    margin-top: 35%;
    color: #000008;
    letter-spacing: 1px;
    text-align: left !important;
    margin-left: 1%;
}

.Second_element12{
    background: #9F9A8E;
    margin-top: 1%;
    margin-left: 1%;
    color: #fff;
    width: 80%;
    height: 200px;
}
.sub_division_right1{
    margin-left: 50px !important;
}

.imgcontent{
    width: 300px !important;
    height: 200px;
}
.textelement12{
    text-align: left !important;
    max-width: 150px;
    padding-left: 0px;
    padding-top: 10px;
}

.subtitle1{
    margin-top: 5px;
    text-align: left;
}
.emyt_first12{
    border: 1px solid #fff !important;
    width: 50%;
    margin-left: 0px !important;
    margin-top: 30px !important;
}

.Find_More12{
    margin-top:5px;
    margin-left: 0px;
    text-align: left;
}
.left{
    width: 100%;
}

.Second_row12{
    background: #DFC4C4;
    width: 95%;
    margin-top: 10px;
}
.Second_row13{
    background: #A9D5DD;;
    width: 95%;
    margin-top: 10px;
}
.property{
    display: flex;
}
.right{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: bottom !important;
    color:#fff !important;
}
.rightelement{
    position: relative;
    margin-top: 10px;
}
.elemnttext{
    position: absolute;
    top: 0;
    font-size: 10px;
    max-width: 50px;
}

.blogpic{
    height: 339px !important;
}

.elemnttext h1{
    font-size: 20px;
    padding-top: 20px;
    padding-left: 20px;
}
.show_More{
    letter-spacing: 1px;
    color: #003177;
    font-family: Alegreya Sans SC;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-weight: bolder;
    text-align: center;
    padding: 10px;
    text-decoration: underline;
}

.sidebar1{
    height: 100%;
    width: 900px;
    position: fixed;
    z-index: 1;
    top: 0px;
    right: 0px;
    overflow-x: hidden;
    padding-right: 0px;
}

@media(max-width:996px){
    
    .sidebar1{
       visibility: hidden;
    }
    
}

@media(max-width:767px){
    .title_header {
        margin-top: 70px;
        font-size: 25px !important;
    }
}

@media(min-width:1024px) and (max-width:1366px){
    .Second_element12 {
        width:100% !important;
    }
    .Second_row12 {
        background: #DFC4C4;
        width: 95%;
        margin-top: 0px;
    }
    .Second_row13 {
        background: #A9D5DD;
        width: 95%;
        margin-top: 0px;
    }
    .rightelement {
        position: relative;
        margin-top: 0px;
    }
    .blogpic {
        height: 320px !important;
    }
}