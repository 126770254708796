#section8{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
}

#section8 .Eightlayertxt{
    position: absolute;
    /* z-index: 995;*/
    z-index: 999;
     width: 100%;
     left: 0;
     top: 10%;
     height: 80%;
     overflow-y: auto;
     overflow-x: hidden;
}


.videoeight .HeaderNumber {
    color: #fff !important;
    padding-left: 50px;
    padding-bottom: 20px;
    text-align: left !important;
    padding-top: 6%;
}

.eightHeader_definition{
    font-weight: 500;
    font-size: 70px;
    line-height: 85px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
}

.start {
    margin-left: 100px;
}

.sl {
    margin-top: 50px;
}

.odd_1 {
    text-align: center;
    padding-top: 50px;
    width: 100px;

}

.odd_1 img {
    width: 100px;

    /* padding-bottom: 15px; */
}

.img_back{
    background: rgba(0, 0, 0, 0.5);
    /* backdrop-filter: blur(4px); */
    border-radius: 8px;
    height: 100px;
    margin-top: 15px;
    clear: both;
}
.even{
    width: 100px;
    /* margin-top: 15px; */
}
.even img{
    width: 100px;
}

.ele {
    margin: auto !important;
}
.videoeight .border_bottom_empty_div {
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
}
.blur_bg{
    background-color: rgba(0, 0, 0, 0.411);  opacity: 1;
}

@media(max-width:991px){
    .hide_medium{
        display: none;
    }
}

@media(min-width:768px) and (max-width:1023px) {

   
    .videoeight .HeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
        padding-top: 9%;
       
    }
    .eightHeader_definition{
        font-size: 50px !important;
        line-height: 75px;
        padding-bottom: 10px;
        padding-left: 50px;  
    }
    .videoeight .border_bottom_empty_div {
        margin-left: 5% !important;
    }
    .videoeight .bottom_color_div {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 70px;
        margin-top: 0px;
    }
    .start {
        margin-left: 100px;
    }
    .sl {
        margin-top: 1%;
    }
}







@media only screen and (max-width:767px) {
    .videoeight .HeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
    
    }
   .eightHeader_definition  {
        font-size: 30px;
        line-height: 85px;
        padding-left: 5% !important;
       
    }
    .videoeight .border_bottom_empty_div {
        margin-left: 5% !important;
    }
    .videoeight .bottom_color_div {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 70px;
        margin-top: 0px;
    }
    .start {
        margin-left: 50px;
    }
    .sl {
        margin-top: 1%;
    }
    .videoeight .border_bottom_empty_div {
        margin-left: 17px;
        width: 50px;
        height: 5px;
        background-color: #fff;
        margin-left: 70px;
    }
    .hide_smallscreen {
        display: none;
    }
    .reducemargintop{
        margin: 0 !important;
        padding: 0 !important;
    }
}
