.blog_element{
    overflow: auto !important;
    height: 100vh !important;
    border: 5px solid pink;
    padding:150px 3%;
}

.para_element {
    margin-top: 15%;
    background: #A89595;
    width: 100%;
    
}

.second_division{
    display: flex;
}

.second_row{
    display: flex;
    padding-top: 1%;
}

.second_division{
    margin-top: 30%;
}
.second_division1{
    margin-top: 10px !important;
    display: flex;
}
.Second_element{   
    width: 35%;
    height: 200px;
    padding: 0;
}

.Second_element1{
    width: 64%;
    /* margin-left: 1%; */
    height: 200px;
  
}
.secondcol{
    display: flex;
}

.secondcol1_row{
    width: 35%;
    margin-left: 1%;
    
}

.secondcol2_row{
    width: 35%;
    margin-left: 1%;
}

.secondcol3_row{
    width: 35%;
    margin-left: 1%;
}

.secondcol2{
    display: flex;
    padding-top: 1%;
}

.secondcol4_row{
    margin-left: 1%;
    width: 60%;
    height: 380px;
}

.secondcol5_row{
    margin-left: 1%;
    margin-top: 1%; 
    width: 39%;
    height: 380px;
}

/**Tags **/

.Tags{
    margin-top: 150px;
    color:#000 !important;

}
.Tag_title{
    color: black;
    text-align: left !important;
   
}
.Tag_items{
    display: flex; 
}

.Tag_item1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
}

.Tag_item2{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}

.Tag_item3,.Tag_item4 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}

.Tag_item5{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #243EE2;
    padding:5px;
    color:#243EE2;
    margin-top: 15px;
}

.Tag_item6{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}

.Tag_item7{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
}


.Tag_item8{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #27891D;
    padding:5px;
    color:#27891D;
    margin-top: 15px;
    padding-right: 50px;
}

.Tag_item9{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}


.Tag_item10{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #B8201A;
    padding:5px;
    color:#B8201A;
    margin-top: 15px;
}

.Tag_item11{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
}

.Tag_item12{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}

.Tag_item13{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #8735D2;
    padding:5px;
    color:#8735D2;
    margin-top: 15px;
}

.Tag_item14{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}

.Tag_item15{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
    padding-right: 50px;
}
.Tag_item16{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
    letter-spacing: 0.5px;
    border-left: 3px solid #828282;
    padding:5px;
    color:#828282;
    margin-top: 15px;
}

.sidebar{
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 10px;
    right: 0px;
    overflow-x: hidden;
    padding-right: 0px;
    border: 2px solid red;
}
.final_txt{
    color: #000;
    text-align: left !important;
    margin-left: -100px !important;
    padding-top: 30px !important;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.5px;
    padding-bottom: 30px !important;
}

 .tags_hightlight{
  display: none;
  margin-top: 0%;
    
}
