#section3{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
  }
  .Thirdoverlay{
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
  }
  
  
  #section3 .thirdlayertxt{
    position: absolute;
  /*  z-index: 998; */
    width: 100%;
    left: 0;
    top: 10%;
    height: 80%;
    z-index:999; 
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .thirdHeaderNumber{
    color: #fff !important;
    padding-left: 50px;
    padding-bottom: 20px;
    text-align: left !important;
    padding-top: 6%;
  }
  
  .thid_Header_definition {
    font-weight: 500;
    font-size: 50px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
  }
  
  .thid_Header_definition  span{
    font-size: 70px;
  }
  
  .third_border_bottom_empty_div{
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
  }
  
  
  
  .third_Paragraph_txt {
    font-size: 28px;
    line-height: 40px;
    max-width: 600px;
    padding-left: 50px;
    text-align: left !important;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .third_Paragraph_txt  span{
    background-color: #fff !important;
    color: #000 !important;
    font-size: 28px;
    line-height: 40px;
    align-items: center;
  }

  
  
  .Thirdrow .cols1 .txt1{
      line-height: 30px;
      width: 80%;
      margin-left: 50px;
      padding-left: 20px;
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      text-align: left !important;
      border-left: 3px solid rgba(255, 255, 255, 0.5);
       margin-top: 3%;
  } 
  
  .thirdsubparagraph_span{
    /* background-color: #fff !important;
    color: #000 !important;
    padding: 2px; */
  }
  
  
  @media(min-width:768px) and (max-width:1023px) {
        
    .thirdHeaderNumber{
      color: #fff !important;
      padding-bottom: 10px;
      padding-top: 9%;
    }
    
    .thid_Header_definition{
      font-size: 30px;
      line-height: 75px;
      padding-bottom: 10px;
      padding-left: 50px;
    }
    
    .thid_Header_definition span{
      font-size: 50px !important;
      line-height: 75px;
    }
    
    .third_Paragraph_txt {
      font-size: 25px;
      line-height: 35px;
      max-width: 560px;
      padding-top: 2%;
      padding-bottom: 2%;
    }
    
    .third_Paragraph_txt  span{
      background-color: #fff !important;
      color: #000 !important;
      font-size: 25px;
      line-height: 35px;
    }
  
    .Thirdrow .cols1 .txt1{
      max-width: 80%;
      font-size: 25px;
      margin-top: 2%;
      line-height: 30px;
    }
    
    }
  @media only screen and (max-width:767px) {
    
  .thirdHeaderNumber{
    color: #fff !important;
    padding-bottom: 10px;
  }
  
  .thid_Header_definition{
    font-size: 20px;
    line-height: 85px;
    padding-left: 50px;
    padding-top: 5%;
  }
  .thid_Header_definition span{
    font-size: 40px !important;
  }
  
  
  
  .third_Paragraph_txt {
    font-size: 15px;
    line-height: 25px;
    max-width: 400px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .third_Paragraph_txt span{
    background-color: #fff !important;
        color: #000 !important;
        font-size: 15px;
        line-height: 25px;
        align-items: center;
  }
  .Thirdrow .cols1 .txt1{
    line-height: 20px;
    max-width: 350px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    margin-top: 5%;
   
  }
  
  
  
  }
  
  
  
  
  