#section4{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
  }


#section4 .fourthlayertxt{
    position: absolute;
   /* z-index: 997;*/
    width: 100%;
    left: 0;
    top: 10%;
    height: 80%;
    z-index:999;
    overflow-y: auto;
    overflow-x: hidden;
}
.Fourthoverlay{
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
}

.fourthHeaderNumber{
    color: #fff !important;
    padding-left: 50px;
    padding-bottom: 20px;
    text-align: left !important;
    padding-top: 6%;
}

.fourthHeader_definition{
    font-weight: 500;
    font-size: 70px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
}

.fourthborder_bottom_empty_div{
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
}

.fourthParagraph_txt{
    font-size: 28px;
    line-height: 40px;
    max-width: 800px;
    padding-left: 50px;
    text-align: left !important;
    padding-top: 2%;
    padding-bottom: 2%;
}

.fourthParagraph_txt span{
    background-color: #fff;
    color: #000;
    font-size: 28px;
    line-height: 40px;
}

.Fourthrow  .cols1 .txt1{
    line-height: 30px;
    max-width: 400px;
    padding-left: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    text-align: left !important;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    margin-top: 10%;
    margin-left: 50px;
} 

@media(min-width:768px) and (max-width:1023px) {
    .fourthHeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
        padding-top: 9%;
       
    }

    .fourthHeader_definition {
        font-size: 50px !important;
        line-height: 75px;
        padding-bottom: 10px;
        padding-left: 50px;  
    }
 
   
  

    .fourthParagraph_txt {
        font-size: 25px;
        line-height: 35px;
        max-width: 600px;
        padding-top: 2%;
        padding-bottom: 2%;
       
    }

    .fourthParagraph_txt span {
        background-color: #fff !important;
        color: #000 !important;
        font-size: 25px;
        line-height: 35px;
    }

    .Fourthrow .cols1 .txt1{
        max-width: 400px;
        font-size: 20px;
        margin-top: 5%;
    margin-left: 50px;
} 


}


@media only screen and (max-width:767px) {
   .fourthHeaderNumber {
    color: #fff !important;
    padding-bottom: 10px;
    }
    .fourthHeader_definition {
        font-size: 40px;
        line-height: 85px;
        padding-top: 5%;
    }
    .fourthParagraph_txt {
        font-size: 15px;
        line-height: 25px;
        width: 400px;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    .fourthParagraph_txt span{
        background-color: #fff !important;
        color: #000 !important;
        font-size: 15px;
        line-height: 25px;
        align-items: center;
     }
    .Fourthrow .cols1 .txt1{
        margin-top: 10%;
        max-width: 350px;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        border-left: 3px solid rgba(255, 255, 255, 0.5);
       
    margin-left: 50px;
}
    
}

