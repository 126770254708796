.cookies_topsection{
  
    overflow: auto;
    height: 100vh;
}
.header_topsection{
    background: #3F3F3F;
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    margin-top: 9%;
    margin-left: 3%;
    width: 91%;
}

.revised_text{
    text-align: left !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-top: 1%;
    padding-left: 1%;
}

.cookies_paratxt1{
    text-align: left !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-top: 1%;
    padding-left: 1%;
   
}
.cookies_paratxt2{
    text-align: left !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-left: 1%;
    max-width: 1400px;
}

.cookies_paratxt3{
    padding-top: 1%;
    text-align: left;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-left: 1%;
    max-width: 1400px;
}

.cookies_paratxt4{
    text-align: left;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-left: 1%;
    padding-bottom: 1%;
   
}

.header_middlesection{
    background: #F1F1F1;
    border-radius: 4px;
    margin-top: 1%;
    margin-left: 3%;
    width: 91%;
    text-align: left !important;
    font-size: 16px;
}

.headermiddle_topsectiontxt{
    color: #000000;
    padding-top: 1%;
    padding-left: 1%;
    font-style: normal;
    font-weight: 500;
}

.headermiddle_subParagrapgh ,.headermiddle_subParagrapgh1,.headermiddle_subParagrapgh2,.headermiddle_subParagrapgh3,.headermiddle_subParagrapgh4,.headermiddle_subParagrapgh5{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
    padding-top: 1%;
}

.headermiddle_subParagrapgh6{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
    padding-top: 1%;
}
.cookiestopsection{
    overflow: scroll !important;
}
.headermiddle_subParagrapgh7 ,.headermiddle_subParagrapgh8 ,.headermiddle_subParagrapgh10{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
    padding-top: 1%;
}
.headermiddle_subParagrapgh9{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
  
}

.headermiddle_subParagrapgh11,.headermiddle_subParagrapgh12{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
}

.headermiddle_subParagrapgh13 ,.headermiddle_subParagrapgh14,.headermiddle_subParagrapgh15,.headermiddle_subParagrapgh16,.headermiddle_subParagrapgh17,
.headermiddle_subParagrapgh18,.headermiddle_subParagrapgh19,.headermiddle_subParagrapgh20,.headermiddle_subParagrapgh21,.headermiddle_subParagrapgh22{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
}

.headermiddle_subParagrapgh23,.headermiddle_subParagrapgh24,.headermiddle_subParagrapgh25,.headermiddle_subParagrapgh26,.headermiddle_subParagrapgh27{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
}

.headermiddle_subParagrapgh28,.headermiddle_subParagrapgh29{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
    padding-top: 1%;
}

.headermiddle_subParagrapgh13 ,.headermiddle_subParagrapgh16,.headermiddle_subParagrapgh18,.headermiddle_subParagrapgh20,
.headermiddle_subParagrapgh21,.headermiddle_subParagrapgh22,.headermiddle_subParagrapgh23{
    padding-top: 1%;
}

.headermiddle_subParagrapgh30{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
    padding-top: 2%;
}

.headermiddle_subParagrapgh31,.headermiddle_subParagrapgh32,.headermiddle_subParagrapgh33{
    color: #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    padding-left: 1%;
    padding-top: 1%;
}