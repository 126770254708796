

.navbar .navbar-brand {
 /* margin-left: 30px; */
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
}
 .common_padding{
     padding-top: 3%;
     position: fixed;
     top: 0;
     z-index: 999;
 }

.dropdown-menu {
    min-width: 0 !important;
}
.navbar-nav{
    margin-right: 50px !important; 
}
.navbar-nav .nav-item .nav-link{
    font-weight: bold;
    font-size: 20px;
}

 @media(min-width:768px) and (max-width:1023px) {
    .common_padding{
        padding-top: 4%;
    }
  }

@media(max-width:767px) {
     .navbar .navbar-brand {
        /* margin-left: 2%; */
    }
    .logo_Img {
        width: 100px;
        height: auto;
    }
    .common_padding{
        padding-top: 5%;

    }   
}

.Navbar_span {
    border-bottom: 3px solid #fff;
    padding-bottom: 10px !important;
}
.default_span{
    color: rgb(167, 164, 164);
}

.dropdown_name{
    font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.2px;
border-bottom: 1px solid #E1E1E1;
color: #333333;
padding-bottom: 10px;
}

.tool_names:hover{
    color:#DB053D
}
