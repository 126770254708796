#section5{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
}
#section5 .fifthlayertxt{
    position: absolute;
   /* z-index: 996;*/
    width: 100%;
    left: 0;
    top: 10%;
    height: 80%;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
}



.fifthHeaderNumber{
    text-align: left !important;
   padding-left: 50px;
   padding-bottom: 20px;
   padding-top: 6%;
}

.fifthHeader_definition{
    font-weight: 500;
    font-size: 70px;
    padding-left: 50px; 
    padding-bottom: 10px;
    text-align: left !important;
}


.fifthborder_bottom_empty_div{
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
}

.fifthParagraph_txt{
    font-size: 28px;
    line-height: 40px;
    max-width: 800px;
    padding-left: 50px;
    text-align: left !important;
    padding-top: 2%;
    padding-bottom: 2%;
}

 .fifthParagraph_txt .subparagraph_txt {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 28px;
    line-height: 40px;
}

.Fifththrow .cols1 .txt1{
    line-height: 30px;
    max-width: 80%;
    padding-left: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    text-align: left !important;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    margin-left: 50px !important;
    margin-top: 4%;
}

@media(min-width:768px) and (max-width:1023px) {
    .fifthHeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
        padding-top: 9%;
    }
    .fifthHeader_definition{
        font-size: 50px !important;
        line-height: 75px;
        padding-bottom: 10px;
        padding-left: 50px;  
    }
  
    .fifthParagraph_txt {
        font-size: 25px;
        line-height: 35px;
        max-width: 560px;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .fifthParagraph_txt .subparagraph_txt{
        background-color: #fff !important;
        color: #000 !important;
        font-size: 25px;
        line-height: 35px;

    }

    .Fifththrow  .cols1 .txt1{
       
        font-size: 20px;
        margin-top: 10%;
      } 
}

@media only screen and (max-width:767px) {
    .fifthHeaderNumber{
        color: #fff !important;
        padding-bottom: 10px;
    }
    .fifthHeader_definition{
        font-size: 40px;
        line-height: 85px;
        padding-left: 50px;
        padding-top: 5%;
    }
    .Fifthcomponent .Header_definition span {
        font-size: 15px !important;
        font-weight: normal;
    }
   
   .fifthParagraph_txt{
       font-size: 15px;
       line-height: 25px;
        max-width: 400px;
        padding-top: 2%;
        padding-bottom: 2%;
   }
   
   .fifthParagraph_txt .subparagraph_txt{
    background-color: #fff !important;
    color: #000 !important;
    font-size: 15px;
    line-height: 25px;
     align-items: center;
   }

   .Fifththrow .cols1 .txt1{
    line-height: 20px;
    max-width: 350px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    margin-top: 10%;
  }
}




