.privacystyle {
    color: white;
    background: black;
    height: 100vh;
    padding-bottom: 3%;
}
.heading{
    font-style: normal;
font-weight: 500;
font-size: 34px;
line-height: 42px;
align-items: center;
color: #FFFFFF;
padding-bottom: 30px;
padding-top: 130px;
}
.pad20{
    padding-top:20px;
}
.pad2{
    padding-top:2px;
}
.pad20font24{
    padding-top:20px;font-weight: 600;font-size: 24px;
}
.padTopBot{
    padding-top:2px;padding-bottom: 50px;
}
.redTop{
    color:red;padding-top:20px;
}
.fontStyle{
    font-weight: 600;font-size: 24px;color:black;
}
.overPad{
    height: 70%;
    width: 90%;margin-left: 5%;margin-right: 5%;
    overflow-y: scroll;padding:40px;background: white;
    margin-bottom: 5%;
}
.overPad div{
    color: black;

}

@media only screen and (max-width: 1411px) {
    .overPad {
        height: 65%;
    }
  }