*:focus {
    outline: none;
 }
 button:focus{
    outline: none;
    cursor: pointer;
 }
 button:hover{
     border: 1px solid grey;
 }

  .connectdesign{
    /* width: 30%; */
    position: absolute; 
    bottom: 55px;
    right: 16px;
    z-index:999;
}
.connectdesign_right{
    background-color: #fff !important;
    margin-top: -50px;
}
.red_text{
    cursor: pointer;
}





.connectdesign_right p  span{
    color:#DB053D !important;
    font-size: 20px;
    margin-left:3px !important;
}
.connectdesign .connecttxt{
    font-size: 12px !important;
    max-width: 270px;
    text-align: left !important;
    line-height: 20px;
}

.connectdesign_left{
    background-color: #DB053D;
    padding: 20px;
    border-radius: 10px 0 0 10px ;
}

.connectdesign_left h1{
    text-align: right!important;
    margin-top: 10px
}

.textboxconnect{
    background: #ffffff;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    border: none;
    color: black;
    padding-left: 10px;
    margin-top: 20px;
}

.textboxconnect:hover{
    border: none;
}

.connectbtn{
    text-align: center !important;
    float: right;
    /* margin-right: 80px; */
    background-color: #ffffff;
    color: #000 !important;
    padding: 10px !important;
    border: 1px solid white;
    border-radius: 10px !important;
    cursor: pointer;
    width: 120px;
    margin-top: 20px;
    font-weight: 500;
    letter-spacing: 1px;
}
.display_none{
    display: none;
}
.yay_div{
    padding-top: 15%;
}