#section2{
    position: absolute;
    z-index: 17;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
  }

  .second_Paragraph_txt span{
    background-color: #fff !important;
    color:#000 !important;
    padding:2px
  }

  .Secondoverlay{
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }

  #section2 .Secondlayertxt{
    position: absolute;
    z-index: 999;
    width: 100%;
    left: 0;
    top: 10%;
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .secondnumber{
    color: #fff !important;
    padding-left: 50px;
    padding-bottom: 20px;
    text-align: left !important;
    padding-top: 6%;
  }

  .secondparagraph1{
    font-weight: 500;
    font-size: 70px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
  }

  .secondparagraph1 span{
    font-size:40px;
  }

  .second_border_bottom_empty_div{
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
  }

 

  .second_Paragraph_txt{
    font-size: 28px;
    line-height: 40px;
    max-width: 630px;
    padding-left: 50px;
    text-align: left ;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .secondrow .cols1 .txt {
    line-height: 30px;
    max-width: 500px;
    padding-left: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    text-align: left !important;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    margin-left: 50px !important;
    margin-top: 5%;
}




@media(min-width:768px) and (max-width:1023px) {
  .secondnumber{
    color: #fff !important;
    padding-bottom: 10px;
    padding-top: 9%;
  }

  .secondparagraph1{
    font-size: 50px !important;
    padding-bottom: 10px;
    padding-left: 50px;  
  }

  .secondparagraph1 span{
    font-size: 30px !important;
  }

  .second_Paragraph_txt{
    max-width: 600px;
    font-size: 25px;
    line-height: 35px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .second_Paragraph_txt span{
    font-size: 25px ;
    line-height: 35px;
  }

  .secondrow .cols1 .txt{
    max-width: 400px;
    font-size: 20px;
    margin-top: 5%;
  } 
}

@media only screen and (max-width:767px) {
  .secondnumber{
    color: #fff !important;
    padding-bottom: 10px;
  }

  .secondparagraph1{
    font-size: 40px;
    line-height: 85px;
        padding-top: 5%;
  }
  .secondparagraph1 span{
    font-size: 20px !important;
    font-weight: normal;
    line-height: 85px;
  }
  .second_Paragraph_txt{
        font-size: 15px;
        line-height: 25px;
        max-width: 360px;
        width: 90%;
        padding-top: 2%;
        padding-bottom: 2%;
  }
  .second_Paragraph_txt .span{
         background-color: #fff !important;
         color: #000 !important;
         font-size: 15px;
         line-height: 25px;
          align-items: center;
  }
  .secondrow .cols1 .txt{
    line-height: 20px;
    max-width: 350px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
  }
}

