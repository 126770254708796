
.chandrika{
    top: 0;
    z-index: 999;
    padding-top: 3%;
    position:fixed !important;
    background-color: #fff !important;
    padding-left: 50px !important;
    width: 100%;
    margin-left: 0px !important;
}

.navbar-toggle-btn{
    background-color: black !important;
}
.toggle-icon-color{
    color: #000 !important;
 
}

