#section6{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
}
#section6 .sixthlayertxt{
    position: absolute;
   /* z-index: 995;*/
   z-index: 999;
    width: 100%;
    left: 0;
    top: 10%;
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

.Sixthcomponent .HeaderNumber {
    text-align: left !important;
    padding-left: 50px;
    padding-bottom: 20px;
    padding-top: 6%;
}

.Sixthcomponent .Header_definition {
    font-weight: 500;
    font-size: 70px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
}

.Sixthcomponent .border_bottom_empty_div {
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
}

.Sixthcomponent .Paragraph_txt {
    font-size: 28px;
    line-height: 40px;
    max-width: 830px;
    padding-left:  50px;
    text-align: left;
    padding-top: 2%;
    padding-bottom: 2%;
}

.Sixthcomponent .Paragraph_txt span {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 28px;
    line-height: 40px;
}

.rows_cols1 .cols1 .txt {
    line-height: 30px;
    max-width: 500px;
    padding-left: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    text-align: left !important;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    margin-top: 5%;
    margin-left: 50px;
}




@media(min-width:768px) and (max-width:1024px) {
    .Sixthcomponent .HeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
        padding-top: 9%;
    }
    .Sixthcomponent .Header_definition {
        font-size: 50px;
        line-height: 75px;
        padding-bottom: 10px;
        padding-left: 50px; 
    }
    .Sixthcomponent .Paragraph_txt {
        font-size: 25px;
        line-height: 35px;
        max-width: 560px;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    .Sixthcomponent .Paragraph_txt span {
        background-color: #fff !important;
        color: #000 !important;
        font-size: 25px;
        line-height: 35px;
    }
    .rows_cols1 .cols1 .txt {
        max-width: 400px;
        font-size: 20px;
        margin-top: 5%;
    }
}

@media only screen and (max-width:767px) {
    .Sixthcomponent .HeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
    }
    .Sixthcomponent .Header_definition {
        font-size: 40px;
        line-height: 85px;
        padding-left: 50px;
        padding-top: 5%;
    }
    
    .Sixthcomponent .Paragraph_txt {
        font-size: 15px;
        line-height: 25px;
        max-width: 400px;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .Sixthcomponent .Paragraph_txt span {
        background-color: #fff !important;
        color: #000 !important;
        font-size: 15px;
        line-height: 25px;
        align-items: center;
    }
 
    .rows_cols1 .cols1 .txt {
        max-width: 400px;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 25px;
        border-left: 3px solid rgba(255, 255, 255, 0.5);
    }
}