.footer_bottom {
    position: absolute;;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    text-align: center;
    z-index: 999;
    padding: 10px;
    /* display: flex; */
}

.termsandpolicy{
    float: right;
}
.copy_right
{
    float:left;
    color: #FFF;
}
.terms
{
    float:left;
    padding: 0 10px;
    cursor:pointer;
}
.link {
    color: #FFF;
  }