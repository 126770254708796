#section9{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center center;
    background-size: contain;
    object-fit: cover; 
}

#section9 .ninthlayertxt{
    position: absolute;
    z-index: 999;
     width: 100%;
     left: 0;
     top: 10%;
     height: 80%;
     overflow-y: auto;
     overflow-x: hidden;
}


.ninth_element_div_left {
    margin-left: 40px;
}

.videoninth .HeaderNumber {
    color: #fff !important;
    padding-left: 50px;
    padding-bottom: 20px;
    text-align: left !important;
    padding-top: 6%;
}

.videoninth .Header_definition {
   
    font-weight: 500;
    font-size: 70px;
    line-height: 85px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
}

.videoninth .border_bottom_empty_div {
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
}

.parentspacing_div {
    margin-top: 150px;
}

.addressheading{
    font-size: 15px;
    text-align: left !important;
    padding-top: 20px;
}

.addresspara{
    font-size: 15px;
    text-align: left !important;
    max-width: 200px !important;
    padding-top: 5px !important;
}

.Location_sub_divs{
    display: flex;
    text-align: left !important;
}
.Location_sub_divs h5{
    color:#fff;
    opacity: 0.9;

}
.Location2{
    margin-left: 0px;
}
.Location1{
  margin-left: 15px;
}
.Location3{
    margin-left: 15px;
  }
.Location4{
    margin-left: 15px;
  }
.border_empty_div_b{
    width: 50px;
    height: 5px;
    background-color: red;
    margin-left: 5px;
  }

 

  .lastbordercolor{
      border-radius: 6px;
      background-color: #000000;
      /* opacity: 0.7; */
      padding: 20px;
      /* margin-left: 50px; */
      margin-top: 100px;
      min-width: 700px;
      display: inline;
      align-items: left;
  }


     .cont_add{
        margin-top: 5%;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0.7; 
        padding: 20px;
        width: 500px;
        margin-left: 50px;
    }  

    
  /* .connectdesign{ */
      /* width: 30%; */
      /* position: absolute;  */
      /* top: 70%; */
      /* right: -0px; */
      /* z-index:999; */
  /* } */
  
  /* .connectdesign_right{ */
      /* background-color: #fff !important; */
      /* margin-top: -50px; */
  /* } */

  /* .connectdesign_right img  {
    transform: rotate(-90deg);

  } */

  /* .connectdesign .connecttxt{
      font-size: 12px !important;
      max-width: 300px;
  } */

  /* .connectdesign_right p {
     
    transform: rotate(90deg) !important;
    margin-top: 60px !important;
   
  } */

  /* .connectdesign_right p  span{
      color:#DB053D !important;
      font-size: 20px;
      margin-left:3px !important;
  } */

  /* .connectdesign_left{
      background-color: #DB053D;
      margin-top: -50px !important;
  } */

  /* .connectdesign_left h1{
      text-align: right!important;
      margin-top: 10px
  } */

  /* .textboxconnect{
      background: #ffffff;
      border-radius: 6px;
      width: 60%;
      height: 40px;
      border: none;
  } */

  /* .textboxconnect:hover{
      border: none;
  } */

  /* .connectbtn{
      text-align: center !important;
      float: right;
      margin-right: 80px;
      background-color: #ffffff;
      color: #000 !important;
      padding: 10px !important;
      border: none;
      border-radius: 10px !important;
      margin-bottom: 10px;
  } */


  @media(min-width:768px) and (max-width:1024px) {
    .videoninth .HeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
        padding-top: 9%;
      
    }

    .videoninth  .Header_definition {
        font-size: 50px !important;
        line-height: 75px;
        padding-bottom: 10px;
        padding-left: 50px;  
    }
    
    .videoninth .border_bottom_empty_div {
        margin-left: 5% !important;
    }

    .videoninth  .bottom_color_div {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 70px;
        margin-top: 0px;
    }


    .lastbordercolor{
        width: 70%;
    }

    
    
    /* .cont_add{
        margin-top: 5%;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.5);
        /* opacity: 0.7; */
        /* padding: 20px;
        width: 600px;
        margin-left: 50px;
    } */ 
 
}







@media(min-width:1200px){
    

    .lastbordercolor{
     
        opacity: 0.7;
        padding: 20px;
        margin-left: 50px;
        margin-top: 100px;
        width: 40%;
    }
  
}

/* @media(max-width:991px){
    .connectdesign{
        display: none;
    }
} */

@media only screen and (max-width:767px) {
    .videoninth .HeaderNumber {
        color: #fff !important;
        padding-left: 50px;
        padding-bottom: 0px;
        
    }
    .videoninth .Header_definition {
       
        font-weight: 500;
        font-size: 30px;
        line-height: 85px;
        padding-left: 50px;
        padding-bottom: 0px;
        padding-top: 0px;
        
    }
    .cont_add{
        margin-top: 5%;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.5);
        /* opacity: 0.7; */
        padding: 20px;
        width: 400px;
        margin-left: 50px;
    }
    .ninth_element_div_left {
        margin-left: 50px;
        font-size: 15px;
    }
    .videoninth .border_bottom_empty_div {
        width: 50px;
        height: 5px;
        background-color: #fff;
        margin-left: 50px;
        padding-top: 0%;
    }

    .addressheading{
        font-size: 15px;
        text-align: left !important;
        padding-top: 20px;
    }

    .addresspara{
        font-size: 15px;
        text-align: left !important;
        max-width: 300px !important;
        padding-top: 5px !important;
    }

    .Location_sub_divs{
        display: flex;
        text-align: left !important;
    }
    .Location_sub_divs h5{
        color:#fff;
        opacity: 0.2;

    }
    .Location2{
      margin-left: 15px;
    }
    .Location3{
        margin-left: 15px;
      }
    .Location4{
        margin-left: 15px;
      }
      .border_empty_div_b{
        width: 50px;
        height: 5px;
        background-color: red;
        margin-left: 5px;
      }

      .lastbordercolor{
        opacity:0.7;
        margin-left:15px;
        margin-top:30px;
        border-radius:5px;
        background-color:"#000000",

      }
      .imagepic{
          width: 300px;
      }
  
}
