#section7{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
   /* background-color: black; /* in case the video doesn't fit the whole page*/
    background-position: center center;
    background-size: contain;
    object-fit: cover; /*cover video background */
}

#section7 .seventhlayertxt{
    position: absolute;
    /* z-index: 995;*/
    z-index: 999;
     width: 100%;
     left: 0;
     top: 10%;
     height: 80%;
     overflow-y: auto;
     overflow-x: hidden;
}

.diana{
    background-color: #000 !important;
    opacity: 0.7;
}


.Seventhcomponent .HeaderNumber {
    text-align: left !important;
   padding-left: 50px;
   padding-bottom: 20px;
   padding-top: 6%;
}

.Seventhcomponent .Header_definition {
    font-weight: 500;
    font-size: 70px;
    padding-left: 50px;
    padding-bottom: 10px;
    text-align: left !important;
}

.seventhborderempty_div{
    width: 50px;
    height: 5px;
    background-color: #fff;
    margin-left: 50px;
}

.seventhelementparentdiv {
    margin-top: 5%;
}


.seventhparagraphelement h5{
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 30px;
    padding-left: 70px;
    text-align: left !important;
}

.seventhparagraphelement p{
    max-width: 400px;
    margin-top: 30px;
   color: white;
    border-radius: 8px;
    margin-left: 50px;
    padding: 20px;
    width: 80%;
    font-size: 16px;
    text-align: left !important;
}

.seventh_colums_paragraph_text1 h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 30px;
    padding-left: 50px;
    text-align: left !important;
}

.seventh_colums_paragraph_text1 p {
    max-width: 400px;
    margin-top: 30px;
   
    border-radius: 8px;
    margin-left: 50px;
    padding: 20px;
    width: 80%;
    font-size: 28px;
    text-align: left !important;
}
.seventh_colums_paragraph_text2 p{
    max-width: 400px;
    margin-top: 30px;
    
    border-radius: 8px;
    margin-left: 50px;
    padding: 20px;
    width: 80%;
    font-size: 28px;
    text-align: left !important;
    padding-bottom: 60px;
}

.seventh_colums_paragraph_text2  h5{
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 30px;
   
    padding-left: 50px;
    text-align: left !important;
}


@media(min-width:768px) and (max-width:1023px) {
    .Seventhcomponent .HeaderNumber {
        color: #fff !important;
        padding-bottom: 10px;
        padding-top: 9%;
       
        
    }
    .Seventhcomponent .Header_definition {
        font-size: 50px !important;
        line-height: 75px;
        padding-bottom: 10px;
        padding-left: 50px; 
    }
    .Seventhcomponent .border_bottom_empty_div {
        width: 50px;
        height: 5px;
        background-color: #fff;
        margin-left: 5%;
    }
    .seventhelementparentdiv {
        margin-top: 3%;
    }
    
    .seventhparagraphelement h5 {    
        padding-left: 70px;
    font-size: 30px;
        line-height: 30px;
        margin-top: 5%;
    }
    .seventhparagraphelement p {
        font-size: 20px;
   color: white;
}

    .seventh_colums_paragraph_text1 h5 {
        font-size: 30px;
        line-height: 30px;
        margin-top: 5%;
    }
   
    .seventh_colums_paragraph_text1 p{
        font-size: 20px;
    }

    .seventh_colums_paragraph_text2  h5 {
        font-size: 30px;
        line-height: 30px;
        margin-top: 5%;
    }

    .seventh_colums_paragraph_text2 p{
        font-size: 20px;
    }
  

 
}


@media only screen and (max-width:767px) {
    .Seventhcomponent .HeaderNumber {
        color: #fff !important;
        padding-bottom: 0px !important;
    }
    .Seventhcomponent .Header_definition {
        font-size: 40px;
        line-height: 85px;
        padding-left: 50px;
        padding-top: 5%;
    }

   
 
    .seventhparagraphelement h5 {
    padding-left: 70px;
    font-size: 20px;
        line-height: 30px;
    }

    .seventhparagraphelement p {  
        font-size: 10px;
   color: white;
}
    .seventh_colums_paragraph_text1 h5 {
        font-size: 20px;
        line-height: 30px;
        margin-left: 25px;
     
    }
  

    .seventh_colums_paragraph_text1 h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        padding-left: 5%;
    }
    .seventh_colums_paragraph_text1 p {
        margin-left: 50px;
        padding: 20px;
        font-size: 10px;
        background: rgba(0, 0, 0, 0.5);
       
    }
    .seventh_colums_paragraph_text2 h5 {
        margin-left: 0px;
        font-size: 20px;
        line-height: 30px;
    
     
    }
    .seventh_colums_paragraph_text2 p {
        margin-left: 50px;
        padding: 20px;
        font-size: 10px;
        background: rgba(0, 0, 0, 0.5);
    }
}

